import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
  },
  state: {
    ownAccount:null,
    shopInfo:null,
    registerShopId:null
  },
  mutations: {
    setOwnAccount(state, n){
      state.ownAccount = n
    },
    setShopInfo(state, n){
      state.shopInfo = n
    },
    registerShopId(state, n){
      state.registerShopId = n
    }
  }
})
export default store
