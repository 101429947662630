
<template>
  <v-app id="inspire">
    <v-navigation-drawer
     v-model="drawer"
     app
     v-if="ownAccount"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ownAccount.shops[0].google_place.info.name}}
          </v-list-item-title>
          <v-list-item-subtitle>
            menu
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to='item.to'
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="grey lighten-5"
      dark
      prominent
      v-if="ownAccount"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" style="color:black;"></v-app-bar-nav-icon>

      <v-app-bar-title style="right:10px; color:black;">
        <p> {{ownAccount.shops[0].google_place.info.name}} </p>
      </v-app-bar-title>

      <v-spacer></v-spacer>

     <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar  class="ma-3" height="36px" width="36px" min-width="30" v-bind="attrs" v-on="on"><v-img src="https://bondeat.sgp1.cdn.digitaloceanspaces.com/postimage/0b2c9999-24dc-4a72-9300-fae4bec7be77"/></v-avatar>
          </template>
          <v-list v-if="!ownAccount">
            <v-list-item :to="menuItems[0]['to']">
              <v-list-item-title>
                Sign In
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-list v-if="ownAccount">
            <v-list-item :to="menuItems[1]['to']">
              <v-list-item-title>
                店舗情報
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-list v-if="ownAccount">
            <v-list-item @click="logout">
              <v-list-item-title>
                Logout
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
    </div>      
    </v-app-bar>


    <v-main>
        <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
       drawer: null,
       items: [
          { title: '利用状況', icon: 'mdi-home', to: '/home' },
          { title: 'チャット一覧', icon: 'mdi-message-text', to: '/chat' },
          { title: 'アナリティクス', icon: 'mdi-poll', to: '/analythics' },
          { title: '店舗情報', icon: 'mdi-account', to: '/shopinfo'}
        ],
        menuItems:[
          {to:'/login'},
          {to:'/shopinfo'}
        ]
    }),
    mounted(){
      let ownAccount = this.$store.state.ownAccount
      if(ownAccount){
        this.$axios.defaults.headers.common["Authorization"] = "Token " + ownAccount.token
      }
    },
    methods:{
      logout(){
        alert("are you sure?")
        localStorage.removeItem("owner-account")
        this.$store.commit('setOwnAccount', null)
        this.$store.commit('registerShopId', null)
        this.$router.push('/')
        try {
          window.webkit.messageHandlers.logout.postMessage('')
        } catch (error) {
          console.log("error")
        }
      },
    },
    computed:{
      ownAccount(){
        return this.$store.state.ownAccount
      } 
    }
  }
</script>


<style scoped>
a {
  color:black;
  text-decoration: none;
}
</style>