<template>
  <v-container class="pa-5" v-if="ownAccount">
    <v-row>
      <v-col
        v-for="n in GraphFirst"
        :key="n.id"
        cols="12"
        sm=4
      >
        <v-card
          class="mx-auto"
          max-width="344"
          height="150px"
          outlined
          :color="n.color"
        >
          <v-list-item three-line>
            <v-list-item-content class="pa-0">
              <v-list-item-title class="text-h4" style="margin-top:60px; color:white;">
                {{ n.count }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar>
              <v-icon color="white" large>{{ n.icon }}</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-subtitle class="pt-0 text-subtitle-2" style="color:white;">{{ n.title }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <div class="example" style="margin-top:30px;">
      <v-card>
        <v-card-subtitle class="grey lighten-2 text-subtitle-2">ページビュー</v-card-subtitle>
        <apexcharts height="350" type="line" :options="chartOptions" :series="placeViewSeries"></apexcharts>
      </v-card>
    </div>

    <v-row justify="center" style="margin-top:30px; margin-bottom:30px;">
      <v-col
        cols="12"
        sm="6"
      >
        <v-card>
          <v-card-subtitle class="grey lighten-2 text-subtitle-2">マップビュー</v-card-subtitle>
          <apexcharts height="350" type="line" :options="chartOptions" :series="mapViewSeries"></apexcharts>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card>
          <v-card-subtitle class="grey lighten-2 text-subtitle-2">電話コール数</v-card-subtitle>
          <apexcharts height="350" type="line" :options="chartOptions" :series="phoneViewSeries"></apexcharts>
        </v-card>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'HomePage',
  components: {
    apexcharts: VueApexCharts,
  },
  data () {
    return {
      newTaskTitle: '',
      GraphFirst: [
        { id: 1, title: '口コミ数', color: 'green', count: 0, icon: 'mdi-format-list-bulleted' },
        { id: 2, title: '写真数', color: '#bf1a20', count: 0, icon: 'mdi-image-outline' },
        { id: 3, title: 'チェックイン数', color: 'blue', count: 0, icon: 'mdi-marker-check' },
        // {id: 4, title: 'ブックマーク数', color: 'amber', count: 428, icon: 'mdi-bookmark'}
      ],
      chartOptions: {
        chart: {
          id: 'basic-bar',
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value.toFixed(0)
            }
          }
        }
      },
      placeViewSeries: [
        {
          name: 'Count',
          data: [],
        },
      ],
      mapViewSeries: [
        {
          name: 'Count',
          data: [],
        },
      ],
      phoneViewSeries: [
        {
          name: 'Count',
          data: [],
        },
      ],
    }
  },
  computed: {
    ownAccount () {
      return this.$store.state.ownAccount
    },
    shop () {
      if (this.$store.state.ownAccount.shops.length > 0) {
        return this.$store.state.ownAccount.shops[0]
      }
      return null
    }
  },
  mounted () {
    if (this.ownAccount) {
      this.$axios.defaults.headers.common["Authorization"] = "Token " + this.ownAccount.token
    }
    this.fetchPageView()
    this.fetchMapView()
    this.fetchPhoneView()
    this.updateShopData()
  },
  methods: {
    addTask () {
      let newTask = {
        id: Date.now(),
        title: this.newTaskTitle,
        done: false,
      }
      this.tasks.push(newTask)
      this.newTaskTitle = ''
    },
    doneTask (id) {
      let task = this.tasks.filter(task => task.id === id)[0]
      task.done = !task.done
    },
    deleteTask (id) {
      this.tasks = this.tasks.filter(task => task.id !== id)
    },
    goSelect () {
      this.$router.push({ path: '/select' })
    },
    fetchPageView() {
      this.$axios.get(`/shop/${this.ownAccount.shops[0].id}/chart/?type=place`)
        .then(resp => {
          this.placeViewSeries = [{ name: 'Count', data: resp.data}]
      })
    },
    fetchMapView() {
      this.$axios.get(`/shop/${this.ownAccount.shops[0].id}/chart/?type=map`)
      .then(resp => {
        this.mapViewSeries = [{ name: 'Count', data: resp.data}]
      })
    },
    fetchPhoneView() {
      this.$axios.get(`/shop/${this.ownAccount.shops[0].id}/chart/?type=phone`)
      .then(resp => {
        this.phoneViewSeries = [{ name: 'Count', data: resp.data}]
      })
    },
    updateShopData () {
      this.GraphFirst[0].count = this.shop.total_post
      this.GraphFirst[1].count = this.shop.total_image
      this.GraphFirst[2].count = this.shop.total_dine
      this.$axios
      .get(`/owner-account/${this.ownAccount.id}/`)
      .then((resp) =>{
        this.$store.commit('setOwnAccount', resp.data)
        localStorage.setItem("owner-account", JSON.stringify(resp.data))
        this.GraphFirst[0].count = this.shop.total_post
        this.GraphFirst[1].count = this.shop.total_image
        this.GraphFirst[2].count = this.shop.total_dine
      })
    }
  },
}
</script>

<style scoped>

</style>
